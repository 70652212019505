import 'bootstrap/dist/css/bootstrap.min.css';

import { Color } from '../../constants';
import React from 'react';
import { RowCentered } from '../../components/Row';
import { Spacer } from '../Container';
import { defaultFontStyle } from '../T';
import styled from 'styled-components';
import { size } from 'lodash';

export const Text = styled.div(() => ({
  ...defaultFontStyle,
}));

export const TextCenter = styled(Text)`
  text-align: center;
`;

export const TextTitle = styled(Text)<{
  size?: string;
  color?: string;
  margin?: string;
  weight?: string;
  lineHeight?: string;
  align?: string;
}>`
  font-family: NEXON Lv1 Gothic;
  font-weight: ${({ weight = 'normal' }) => weight};
  font-size: ${({ size = '16px' }) => size};
  color: ${({ color = '' }) => color};
  margin: ${({ margin = '' }) => margin};
  line-height: ${({ lineHeight = '1.3em' }) => lineHeight};
  text-align: ${({ align = 'left' }) => align};
`;

export const TextStyled = styled(Text)<{
  size?: string;
  color?: string;
  margin?: string;
  align?: string;
  weight?: string;
}>`
  font-size: ${({ size = '' }) => size};
  color: ${({ color = '' }) => color};
  margin: ${({ margin = '' }) => margin};
  text-align: ${({ align = '' }) => align};
  font-weight: ${({ weight = '' }) => weight};
`;

export const Label = styled.span(() => ({
  ...defaultFontStyle,
}));

export const LabelStyled = styled(Label)<{ fontSize?: string }>`
  font-size: ${({ fontSize = '' }) => fontSize};
`;

export const TextSecondary = styled.div(() => ({
  ...defaultFontStyle,
  color: Color.secondary1,
}));

export const TextSub = styled.span<{ unlocked?: boolean; color?: string }>`
  font-size: '10px';
  color: ${({ color, unlocked }) => (color ? (unlocked ? color : Color.primary2) : Color.primary2)};
  opacity: ${({ unlocked }) => (unlocked ? '1' : '0.4')};
`;

export const TextBold = styled.strong(() => ({
  ...defaultFontStyle,
}));

export const Header = styled(Text)({
  fontSize: '20px',
  fontWeight: 'bold',
  lineHeight: '29px',
});

export const Divider = styled.div(() => ({
  height: '1px',
  margin: '22px 0',
  borderTop: 'solid 1px #e9ebee',
}));

export const DividerLabel = ({ children }: any) => (
  <RowCentered>
    <Divider></Divider>
    <Spacer width={'28px'} />
    <Text style={{ color: Color.secondary1, marginTop: '7px' }}>{children}</Text>
    <Spacer width={'28px'} />
    <Divider></Divider>
  </RowCentered>
);

export const ListTitle = styled(Text)<{ gutter?: string }>`
  color: ${Color.secondary1};
  font-size: 12px;
  margin-right: ${({ gutter = '8px' }) => gutter};
  margin-bottom: ${({ gutter = '8px' }) => gutter};
`;

export const Title = styled(Text)({
  color: Color.secondary1,
  fontSize: '14px',
});

export const SubTitle = styled(Text)({
  fontSize: 12,
  fontWeight: 'bold',
  color: Color.secondary1,
  marginBottom: 14,
});

export const ListTitleBold = styled(ListTitle)<{ gutter?: string }>`
  font-weight: bold;
  margin-right: ${({ gutter = '8px' }) => gutter};
  margin-bottom: ${({ gutter = '8px' }) => gutter};
`;

export const ListItemTitle = styled(TextBold)({
  width: '100%',
  backgroundColor: Color.background,
  color: Color.primary2,
  fontSize: '16px',
});

export const ListItemDesc = styled(Text)({
  color: Color.secondary1,
  margin: '12px 0',
});

export const ListItemSub = styled(Text)<{ fontSize?: string }>`
  background-color: ${Color.background};
  color: ${Color.secondary1};
  font-size: ${({ fontSize = '12px' }) => fontSize};
  line-height: 1.4em;
  .price {
    padding: 0 3px 0 5px;
    font-weight: bold;
    color: ${Color.primary1};
  }
`;

export const ListItemSub2 = styled(Text)({
  backgroundColor: Color.background,
  color: Color.secondary1,
});

export const Notification = styled(Text)<{
  bgcolor?: string;
  textcolor?: string;
  fontsize?: string;
  bordercolor?: string;
}>`
  padding: 12px;
  border-radius: 10px;
  border: solid 1px ${({ bordercolor = '#e8e3bc' }) => bordercolor};
  background-color: ${({ bgcolor = '#fbf7d8' }) => bgcolor};
  text-align: center;
  font-size: ${({ fontsize = '12px' }) => fontsize};
  color: ${({ textcolor = '#978B32' }) => textcolor};
`;

export const UnderlineText = styled(Text)`
  color: ${Color.primary2};
  text-decoration: underline;
`;

export const UnderlineTextLinked = styled(UnderlineText)`
  cursor: pointer;
`;
