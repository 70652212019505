import styled, { StyledComponent } from 'styled-components';

import { Color } from '../../constants';
import React from 'react';

export type FontWeight = 300 | 500;
export type FontSize = 24 | 18 | 16 | 14 | 12;

const LETTER_SPACING_MAP = {
  24: -0.36,
  18: -0.27,
  16: -0.24,
  14: -0.21,
  12: -0.18,
};

export const defaultFontStyle = {
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: Color.fontColor,
};

export const T = styled.span(defaultFontStyle);

const f = (fontSize: FontSize) => ({ fontSize, letterSpacing: LETTER_SPACING_MAP[fontSize] });
export const fontConfig = f;

export const T24 = styled(T)(f(24));
export const T18 = styled(T)(f(18));
export const T16 = styled(T)(f(16));
export const T14 = styled(T)(f(14));
export const T12 = styled(T)(f(12));

export const T24P1 = styled(T)({ ...f(24), color: Color.primary1 });
export const T18P1 = styled(T)({ ...f(18), color: Color.primary1 });
export const T16P1 = styled(T)({ ...f(16), color: Color.primary1 });
export const T16P1B = styled(T)({ ...f(16), color: Color.primary1, fontWeight: 500 });
export const T14P1 = styled(T)({ ...f(14), color: Color.primary1 });
export const T12P1 = styled(T)({ ...f(12), color: Color.primary1 });

export const T24P2 = styled(T)({ ...f(24), color: Color.primary2 });
export const T24P2B = styled(T)({ ...f(24), color: Color.primary2, fontWeight: 500 });
export const T18P2 = styled(T)({ ...f(18), color: Color.primary2 });
export const T16P2 = styled(T)({ ...f(16), color: Color.primary2 });
export const T16P2B = styled(T)({ ...f(16), color: Color.primary2, fontWeight: 500 });
export const T14P2 = styled(T)({ ...f(14), color: Color.primary2 });
export const T14P2B = styled(T)({ ...f(14), color: Color.primary2, fontWeight: 500 });
export const T12P2 = styled(T)({ ...f(12), color: Color.primary2 });

export const T24S1 = styled(T)({ ...f(24), color: Color.secondary1 });
export const T18S1 = styled(T)({ ...f(18), color: Color.secondary1 });
export const T16S1 = styled(T)({ ...f(16), color: Color.secondary1 });
export const T14S1 = styled(T)({ ...f(14), color: Color.secondary1 });
export const T12S1 = styled(T)({ ...f(12), color: Color.secondary1 });
export const T12S1B = styled(T)({ ...f(12), color: Color.secondary1, fontWeight: 500 });

export const T24S2 = styled(T)({ ...f(24), color: Color.secondary2 });
export const T18S2 = styled(T)({ ...f(18), color: Color.secondary2 });
export const T16S2 = styled(T)({ ...f(16), color: Color.secondary2 });
export const T14S2 = styled(T)({ ...f(14), color: Color.secondary2 });
export const T12S2 = styled(T)({ ...f(12), color: Color.secondary2 });

export const T24LS2 = styled(T)({ ...f(24), color: Color.lightSecondary2 });
export const T18LS2 = styled(T)({ ...f(18), color: Color.lightSecondary2 });
export const T16LS2 = styled(T)({ ...f(16), color: Color.lightSecondary2 });
export const T14LS2 = styled(T)({ ...f(14), color: Color.lightSecondary2 });
export const T12LS2 = styled(T)({ ...f(12), color: Color.lightSecondary2 });

export const T24I = styled(T)({ ...f(24), color: Color.important });
export const T18I = styled(T)({ ...f(18), color: Color.important });
export const T16I = styled(T)({ ...f(16), color: Color.important });
export const T14I = styled(T)({ ...f(14), color: Color.important });
export const T12I = styled(T)({ ...f(12), color: Color.important });

export const T24SI = styled(T)({ ...f(24), color: Color.secondaryImportant });
export const T18SI = styled(T)({ ...f(18), color: Color.secondaryImportant });
export const T16SI = styled(T)({ ...f(16), color: Color.secondaryImportant });
export const T14SI = styled(T)({ ...f(14), color: Color.secondaryImportant });
export const T12SI = styled(T)({ ...f(12), color: Color.secondaryImportant });

export const T24B = styled(T)({ ...f(24), color: Color.background });
export const T24BB = styled(T)({ ...f(24), color: Color.background, fontWeight: 500 });
export const T18B = styled(T)({ ...f(18), color: Color.background });
export const T16B = styled(T)({ ...f(16), color: Color.background });
export const T14B = styled(T)({ ...f(14), color: Color.background });
export const T12B = styled(T)({ ...f(12), color: Color.background });
