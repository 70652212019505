import React from 'react';
import styled from 'styled-components';
import { defaultFontStyle } from '../T';
import { Text } from '../Typography';
import { Color } from '../../constants';

export const Container = styled.div<{
  justify?: string;
  grow?: string;
  background?: string;
  align?: string;
  padding?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: ${({ grow = '0' }) => grow};
  padding: ${({ padding = '24px' }) => padding};
  width: 100%;
  height: 100%;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  background-color: ${({ background = 'transparent' }) => background};
  ${({ align = '' }) => `align-items: ${align};`};
`;

export const ContainerItem = styled(Container)<{ margin?: string }>`
  padding: 0;
  width: 100%;
  margin: ${({ margin = '0 0 24px 0' }) => margin};
`;

export const ContainerFullHeight = styled(Container)`
  height: calc(var(--vh, 1vh) * 100);
`;

export const ContainerWhite = styled(Container)`
  background-color: #fff;
`;

export const ContainerFlexGrow = styled.div<{ justify?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  justify-content: ${({ justify = 'center' }) => justify};
`;

export const ContainerFlexShrink = styled.div`
  flex-shrink: 0;
`;

export const ContainerFlexRow = styled.div`
  display: flex;
  button {
    flex: 1 1 0;
  }
`;

export const ContainerRelative = styled.div`
  position: relative;

  .profile_image {
    border-radius: 60px;
    background-color: #ffffff;
  }
`;

export const ContainerFlexSB = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerWrapper = ({ children }: { children: any }) => {
  return (
    <section style={{ marginBottom: '14px', backgroundColor: Color.background }}>
      <Container>{children}</Container>
    </section>
  );
};

export const FixedWrapper = styled.div({
  width: '100%',
  maxWidth: '640px',
  position: 'fixed',
  bottom: '0',
});

export const FloatingFixedWrapper = styled(FixedWrapper)`
  background-color: ${Color.background};
  border-top: 1px solid #f0f0f0;
  z-index: 20;
  button {
    border: transparent !important;
    &:disabled {
      background-color: #f0f1f2;
    }
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -8px;
    height: 8px;
    background-image: url(/bg-nav-shadow-top.png);
    background-position: left top;
    background-size: 16px auto;
    opacity: 0.3;
  }
`;

export const Spacer = styled.div<{ width?: string; height?: string }>`
  background: transparent;
  width: ${({ width = '10px' }) => width};
  height: ${({ height = 0 }) => height};
`;

export const GroupContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ListContainer = styled.div<{ height?: string; padding?: string; background?: string }>`
  padding: 24px;
  margin-bottom: 50px;
`;

export const ListItem = styled.div<{
  position?: string;
  flex?: string;
  height?: string;
  padding?: string;
  background?: string;
  borderRadius?: string;
  margin?: string;
  minHeight?: string;
}>`
  width: 100%;
  margin: ${({ margin = '0 0 20px 0' }) => margin};
  padding: ${({ padding = '' }) => padding};
  min-height: ${({ minHeight = '' }) => minHeight};
  position: relative;
  display: flex;
  flex-direction: ${({ flex = 'column' }) => flex};
  border-radius: ${({ borderRadius = '10px' }) => borderRadius};
  box-shadow: 0 0 10px 0 rgba(22, 29, 36, 0.03);
  background-color: ${({ background = '#ffffff' }) => background};
`;

export const Right = styled.span({
  ...defaultFontStyle,
  backgroundColor: 'transparent',
  float: 'right',
});

export const ListItemElement = styled.div({
  flexGrow: 1,
  flex: '0 0 33.333333%',
  backgroundColor: '#ffffff',
});

export const List = styled.ul({
  width: '100%',
  backgroundColor: 'transparent',
  padding: '0',
  margin: '0',
});

const Li = styled.li({
  listStyle: 'none',
  backgroundColor: 'transparent',
});

export const Item = ({ children, append, color }: { children: any; append: any; color?: any }) => (
  <>
    <Li>
      <Text
        style={{
          display: 'inline-block',
          width: '60%',
          margin: '0',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Text>
      <Right style={{ color: `${color}` }}>{append}</Right>
    </Li>
  </>
);
