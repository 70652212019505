import styled from 'styled-components';

export const Row = styled.div(() => ({
  display: 'flex',
}));

export const RowCentered = styled(Row)`
  justify-content: center;
`;
export const RowRight = styled(Row)`
  justify-content: flex-end;
`;
export const RowLeft = styled(Row)`
  justify-self: right;
`;

export const RowBetween = styled(Row)`
  justify-content: space-between;
`;
